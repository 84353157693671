import React, { PureComponent } from "react"
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom"
import "assets/vendors/style"
import "styles/wieldy.less"
import configureStore, { history } from './appRedux/store'
import App from "./containers/App/index"
import {
  NotificationManager, NotificationContainer
} from "react-light-notifications"
import "react-light-notifications/lib/main.css"
import socketIOClient from "socket.io-client"
import { HOST_ROOT_SOCKET_IO } from "./configs/configs"

const ENDPOINT = HOST_ROOT_SOCKET_IO

const store = configureStore(/* provide initial state if any */)

class NextApp extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dataNotifications: null,
      socketio: null,
    }
  }

  gotoMessaing() {
    window.location.href = '/messaging'
  }

  onNotification() {
    const LocalUserId = localStorage.getItem('USER_ID')
    let socket = socketIOClient(ENDPOINT)
    this.setState({socketio:socket})
    socket.emit('token', { token: localStorage.getItem('USER_TOKEN') })
    socket.on('/chat/group_message', data => {
      let dataMessage = data
      let userId = dataMessage.data.userId
      if (userId !== LocalUserId) {
        NotificationManager.info({
          title: `Notification from ${dataMessage.data.userName}`,
          message: dataMessage.data.message,
          onClick: () => this.gotoMessaing()
        })
      }
    })
  }

  componentDidMount() {
    this.onNotification()
  }

  render() {
    return (
      <>
        <Provider store={store}>
          <NotificationContainer />
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" render={props => <App {...props} socketio={this.state.socketio}/>} />
            </Switch>
          </ConnectedRouter>
        </Provider>
      </>
    )
  }
}

export default NextApp