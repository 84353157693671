import React, { Component } from "react"
import Nprogress from "nprogress"
import ReactPlaceholder from "react-placeholder"
import "nprogress/nprogress.css"
import "react-placeholder/lib/reactPlaceholder.css"
import CircularProgress from "components/CircularProgress"
import Idle from 'react-idle'
import { clear } from "./Storage"

export default function asyncComponent(importComponent, socketio) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        socketio
      };
      Nprogress.start();
    }

    // componentWillMount() {
    //   Nprogress.start();
    // }

    componentWillUnmount() {
      this.mounted = false;
    }

    // onNotification = async () => {
    //   console.log('aaa')
    //   const socket = socketIOClient(ENDPOINT);
    //   socket.emit('token', {token: localStorage.getItem('USER_TOKEN')})
    //   socket.on('/chat/private_message', data => {
    //     NotificationManager.custom({
    //       title: 'Custom',
    //       message: (JSON.stringify(data, null, 2)),
    //       bgColor: '#23A9F2',
    //     })
    //   })
    // }

    async componentDidMount() {
      this.mounted = true;
      const { default: Component } = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} socketio={socketio}/>
        });
      }
    }


    onIdle = async () => {
      clear()
      window.location.href = '/'
    }

    render() {
      const Component = this.state.component || <CircularProgress />;
      return (
        <>
          <Idle
            timeout={3600000}
            onChange={(idle) => this.onIdle(idle)}
          />
          <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
            {Component}
          </ReactPlaceholder>
        </>
      );
    }
  }

  return AsyncFunc;
}
