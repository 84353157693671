import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SIGNIN_USER } from "../../constants/ActionTypes"
import {
  FEATURES,
  GROUP_ID,
  GROUP_NAME,
  ACCESS,
  STATUSES,
  USER_DESCRIPTION,
  USER_EMAIL,
  USER_ID,
  USER_NAME,
  USER_PHONE,
  USER_TOKEN,
  BRANCH_OFFICE_ID,
  BRANCH_OFFICE_NAME
} from "../../constants/LocalStorage"
import { showAuthMessage, userSignInSuccess } from "../../appRedux/actions/Auth"
import axios from 'axios'
import { API_KEY, API_ROOT } from '../../configs/configs'

const options = {
  headers: {
    'x-api-key': API_KEY,
    'Content-Type': 'application/json'
  }
}

const signInUserWithEmailPasswordRequest = async (SIGNIN_USER) =>
  await axios.post(`${API_ROOT}/login/user_auth`, SIGNIN_USER, options)
    .then(authUser => authUser)


const getProfile = async () =>
  await axios.get(`${API_ROOT}/user/user/current`, options)
    .then(async result => {
      if (result.status === 200) {
        const data = result.data.data
        localStorage.setItem(USER_ID, data.id)
        localStorage.setItem(USER_NAME, data.name)
        localStorage.setItem(USER_DESCRIPTION, data.description)
        localStorage.setItem(USER_EMAIL, data.email)
        localStorage.setItem(USER_PHONE, data.phone)
        localStorage.setItem(GROUP_ID, data.groupId)
        localStorage.setItem(GROUP_NAME, data.groupName)
        localStorage.setItem(BRANCH_OFFICE_ID, data.branchOfficeId)
        localStorage.setItem(BRANCH_OFFICE_NAME, data.branchOfficeName)

        await getStatusConstants()
        await getFeatureConstants()
        await getAccessConstants()
      }
      return result
    })
    .catch(error => error);

async function getFeatureConstants() {
  await axios.get(`${API_ROOT}/common/feature_number`, options)
    .then(result => {
      if (result.status === 200)
        localStorage.setItem(FEATURES, JSON.stringify(result.data.data))
      localStorage.setItem('NEW_MESSAGE', JSON.stringify([]))
      return result
    })
    .catch(error => error)
}

async function getStatusConstants() {
  await axios.get(`${API_ROOT}/common/account_status`, options)
    .then(result => {
      if (result.status === 200)
        localStorage.setItem(STATUSES, JSON.stringify(result.data.data))
      return result
    })
    .catch(error => error)
}

async function getAccessConstants() {
  await axios.get(`${API_ROOT}/common/access_type`, options)
    .then(result => {
      if (result.status === 200)
        localStorage.setItem(ACCESS, JSON.stringify(result.data.data))
      return result
    })
    .catch(error => error)
}

function* signInUserWithEmailPassword({ payload }) {
  const body = {
    username: payload.username,
    password: payload.password,
    device: "0",
    application: "65adf62f81e5f64101f1eecb8443153cf2e172e57e3e740282423dc5fe130757"
  }
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, body)
    const token = signInUser.data.data.token
    localStorage.setItem(USER_TOKEN, token)

    options.headers.Authorization = `Bearer ${token}`
    yield call(getProfile)
    yield put(userSignInSuccess(token))
  } catch (error) {
    if(error.response.data.error === 'AUTH_NOT_FOUND'){
      yield put(showAuthMessage('User Not Found'));
    }else if(error.response.data.error === 'AUTH_WRONG_USERNAME_PASSWORD'){
      yield put(showAuthMessage('Invalid Username or Password'));
    }else if(error.response.data.error === 'USER_STATUS:DELETED'){
      yield put(showAuthMessage('User has been deleted'));
    }else{
      yield put(showAuthMessage(error));
    }
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export default function* rootSaga() {
  yield all([fork(signInUser)]);
}
