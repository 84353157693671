import React, { useEffect, Fragment } from "react"
import { Button, Input, message, Form } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom";
import { hideMessage, showAuthLoader, userSignIn } from "../appRedux/actions/Auth"
// import IntlMessages from "../util/IntlMessages"
import CircularProgress from "../components/CircularProgress/index"
import { LogoBareskrim } from '../assets/geospatial/images/provide.images'
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined"
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined"
import moment from 'moment'
import { Helmet } from 'react-helmet'
import LoginVideo from '../assets/geospatial/images/Login/login.mp4'

const SignIn = () => {
  const dispatch = useDispatch()
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth)
  const history = useHistory()

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/')
    }
  })


  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const onFinish = values => {
    dispatch(showAuthLoader())
    dispatch(userSignIn(values))
  }

  const year = moment().format('YYYY')

  return (
    <Fragment>
      <Helmet>
        <meta charSet='UTF-8' />
        <title>Login - Geospatial Analytics Center</title>
      </Helmet>
      <div className="gx-app-login-wrap gx-login-background">
        <video autoPlay
          loop
          muted id="myVideo">
          <source src={LoginVideo} type="video/mp4" />
        </video>
        <div className="gx-app-login-container">
          <div className="gx-login-logo">
            <img src={LogoBareskrim} alt="logo" />
          </div>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content">
              <div className="gx-login-header gx-text-center">
                <h1 className="gx-login-title">GEOSPATIAL ANALYTICS CENTER</h1>
              </div>
              <Form
                initialValues={{ remember: true }}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="gx-signin-form gx-form-row0">
                <Form.Item
                  // initialValue="admin"
                  rules={[{ required: true, message: 'The input is not valid Username' }]} name="username">
                  <Input placeholder="Username" prefix={<UserOutlined className="gx-login-icon-form" />} />
                </Form.Item>
                <Form.Item
                  // initialValue="udin"
                  rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                  <Input type="password" placeholder="Password" prefix={<LockOutlined className="gx--login-icon-form" />} />
                </Form.Item>
                <Form.Item className="gx-text-center">
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    LOGIN
                    </Button>
                </Form.Item>
                <p
                  className="gx-text-light gx-fs-sm gx-login-copyright"> © {year} All Right Reserved</p>
              </Form>
            </div>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SignIn
