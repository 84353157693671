import React from "react"
import { Menu } from "antd"
import { Link, useLocation } from "react-router-dom"
import CustomScrollbars from "util/CustomScrollbars"
import SidebarLogo from "./SidebarLogo"

import {
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting"
import { iconHotspot, iconHotspotActive } from '../../assets/geospatial/images/provide.images'
import IntlMessages from "../../util/IntlMessages"
import { useSelector } from "react-redux"

const SubMenu = Menu.SubMenu;

const SidebarContent = () => {

  let { pathname } = useSelector(({ common }) => common);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1]

  const menuDashboard =
    (<SubMenu key="dashboard" popupClassName={getNavStyleSubMenuClass(navStyle)}
      title={<span><i className="icon icon-dasbhoard" />
        <span><IntlMessages id="sidebar.dashboard" /></span></span>}>
      {
        <Menu.Item key="summary-hotspot">
          <Link to="/summary-hotspot">
            <i className="icon icon-dasbhoard" />
            <span>Summary Hotspot</span>
          </Link>
        </Menu.Item>
      }
      {
        <Menu.Item key="summary-report">
          <Link to="/summary-report">
            <i className="icon icon-dasbhoard" />
            <span>Summary Report</span>
          </Link>
        </Menu.Item>
      }
      {
        <Menu.Item key="prediction-hotspot">
          <Link to="/prediction-hotspot">
            <i className="icon icon-dasbhoard" />
            <span>Prediction Hotspot</span>
          </Link>
        </Menu.Item>
      }
    </SubMenu>)
  const location = useLocation()
  const menuHotspot =
    (
      <SubMenu key="menu-hotspot" popupClassName={getNavStyleSubMenuClass(navStyle)}
        title={<span> <i className='icon'><img src={location.pathname === '/hotspot' || location.pathname === '/detail-hotspot' ? iconHotspotActive : iconHotspot} alt="icon hotspot" /></i>
          <span>Hotspot</span></span>}>
        {
          <Menu.Item key="hotspot">
            <Link to="/hotspot"><i className='icon'><img src={location.pathname === '/hotspot' ? iconHotspotActive : iconHotspot} alt="icon hotspot" /></i>
              <span>Hotspot Maps</span>
            </Link>
          </Menu.Item>
        }
        {
          <Menu.Item key="detail-hotspot">
            <Link to="/detail-hotspot">
              <i className="icon icon-crm" />
              <span>Detail Hotspot</span>
            </Link>
          </Menu.Item>
        }

      </SubMenu>)
  const menuGeoManagement =
    (<Menu.Item key="geospatial-management">
      <Link to="/geospatial-management"><i className="icon icon-map-simple" />
        <span><IntlMessages id="sidebar.geospatial.management" /></span>
      </Link>
    </Menu.Item>)
  const menuMessaging =
    (<SubMenu key="collaboration" popupClassName={getNavStyleSubMenuClass(navStyle)}
      title={<span> <i className="icon icon-team" />
        <span><IntlMessages id="sidebar.collaboration" /></span></span>}>
      {
        <Menu.Item key="messaging">
          <Link to="/messaging">
            <i className="icon icon-email" />
            <span><IntlMessages id="sidebar.messaging" /></span>
          </Link>
        </Menu.Item>
      }
      {
        <Menu.Item key="broadcast">
          <Link to="/broadcast">
            <i className="icon icon-alert" />
            <span><IntlMessages id="sidebar.broadcast" /></span>
          </Link>
        </Menu.Item>
      }
    </SubMenu>)
  const menuPreventionReport =
    (<SubMenu key="prevention-report"
      popupClassName={getNavStyleSubMenuClass(navStyle)}
      title={
        <span>
          <i className="icon icon-editor" />
          <span>Laporan Pencegahan</span>
        </span>
      }>
      <Menu.Item key="prevention_101">
        <Link to="/reporting/prevention/101">
          <i className="icon icon-editor" />
          Patroli Karhutla
        </Link>
      </Menu.Item>
      <Menu.Item key="prevention_102">
        <Link to="/reporting/prevention/102">
          <i className="icon icon-editor" />
          Sosialisasi Karhutla
        </Link>
      </Menu.Item>
      <Menu.Item key="prevention_103">
        <Link to="/reporting/prevention/103">
          <i className="icon icon-editor" />
          Koordinasi
        </Link>
      </Menu.Item>
      <Menu.Item key="prevention_104">
        <Link to="/reporting/prevention/104">
          <i className="icon icon-editor" />
          Bangun Kanal Embung Karhutla
        </Link>
      </Menu.Item>
      <Menu.Item key="prevention_105">
        <Link to="/reporting/prevention/105">
          <i className="icon icon-editor" />
          Bangun Menara Karhutla
        </Link>
      </Menu.Item>
      <Menu.Item key="prevention_106">
        <Link to="/reporting/prevention/106">
          <i className="icon icon-editor" />
          Inovasi Lain
        </Link>
      </Menu.Item>
      <Menu.Item key="prevention_107">
        <Link to="/reporting/prevention/107">
          <i className="icon icon-editor" />
          Monitoring Titik Panas
        </Link>
      </Menu.Item>
    </SubMenu>)
  const menuOutageReport =
    (<Menu.Item key="outage-report">
      <Link to="/reporting/fire_fighting"><i className="icon icon-editor" />
        <span>Laporan Pemadaman</span>
      </Link>
    </Menu.Item>)
  const menuLawEnforceReport =
    (<Menu.Item key="law-enforce-report">
      <Link to="/reporting/law_enforce"><i className="icon icon-editor" />
        <span>Laporan Penegakan Hukum</span>
      </Link>
    </Menu.Item>)
  const menuHotspotReport =
    (<Menu.Item key="hotspot-report">
      <Link to="/reporting/hot_spot"><i className="icon icon-editor" />
        <span>Laporan Titik Panas</span>
      </Link>
    </Menu.Item>)
  const menuVerifyHotspotReport =
    (<Menu.Item key="verify-hotspot-report">
      <Link to="/reporting/verify_hot_spot"><i className="icon icon-editor" />
        <span>Laporan Verifikasi Titik Api</span>
      </Link>
    </Menu.Item>)

  const menuTaskManagement =
    (<Menu.Item key="task-management">
      <Link to="/task-management"><i className="icon icon-folder-o" />
        <span>Task management</span>
      </Link>
    </Menu.Item>)
  // const menuAuditrail =
  //   (<Menu.Item key="audit-trail">
  //     <Link to="/audit-trail"><i className="icon icon-folder-o" />
  //       <span>Audit Trail</span>
  //     </Link>
  //   </Menu.Item>)
  return (
    <>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            <div className="ant-menu-item-group-title" title="Main Menu">
              <p className="margin-main-menu">Main Menu</p>
            </div>
            {menuDashboard}
            {menuHotspot}
            {menuMessaging}
            {menuGeoManagement}
            {menuTaskManagement}

            <div className="ant-menu-item-group-title" title="Main Menu">
              <p className="margin-main-menu">Reporting</p>
            </div>
            {menuPreventionReport}
            {menuOutageReport}
            {menuLawEnforceReport}
            {menuHotspotReport}
            {menuVerifyHotspotReport}

            {/* <div className="ant-menu-item-group-title" title="Main Menu">
              <p className="margin-main-menu">Management</p>
            </div> */}
            {/* {menuUserManagement}
            {menuGroupManagement} */}
            {/* {menuDeviceManagement}
            {menuAuditrail} */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

