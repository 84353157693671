import { API_KEY, API_ROOT } from '../configs/configs';
import { formatUrlWithQueries } from './UrlFormat';
import { USER_TOKEN } from '../constants/LocalStorage';

export async function fetchData(endpoint = '/', queries = {}, fetchOptions = {}) {

  let token = localStorage.getItem(USER_TOKEN);

  let authorizedHeader = {
    Authorization: `Bearer ${token}`,
    'x-api-key': API_KEY
  };

  let defaultFetchOptions = {
    method: 'GET',
    headers: authorizedHeader
  };

  let unformattedUrl
  if (endpoint.startsWith('https://') || endpoint.startsWith('http://') || endpoint.startsWith('ftp://')) {
    unformattedUrl = endpoint
  } else {
    unformattedUrl = `${API_ROOT}${endpoint}`
  }
  const url = formatUrlWithQueries(unformattedUrl, queries)
  let fetchResponse = {}
  try {
    fetchResponse = await fetch(url, {
      ...defaultFetchOptions,
      ...fetchOptions,
      headers: {
        ...defaultFetchOptions.headers,
        ...fetchOptions.headers,
      }
    })
    if (!fetchResponse.ok) {
      const jsonResponse = await fetchResponse.json()
      if (jsonResponse.status !== 'OK') {
        console.log("error", jsonResponse)
        throw new Error(`Server returned message : ${jsonResponse.message}`)
      } else
        throw new Error(`Server returned http status : ${fetchResponse.statusText}`)
    }
  } catch (err) {
    throw new Error(`Server connection problem : ${err.message}`)
  }
  return fetchResponse
}

export async function apiFetch(endpoint = '/', queries = {}, fetchOptions = {}) {
  const fetchResponse = await fetchData(endpoint, queries, fetchOptions);
  const jsonResponse = await fetchResponse.json();
  if (jsonResponse.data) {
    return jsonResponse.data;
  } else {
    return jsonResponse;
  }
}

export async function apiFetchRaw(endpoint = '/', queries = {}, fetchOptions = {}) {
	const fetchResponse = await fetchData(endpoint, queries, fetchOptions);
	const jsonResponse = await fetchResponse.json();
	if (jsonResponse) {
		return jsonResponse;
	} else {
		return jsonResponse;
	}
}

export async function apiFetchPermission(endpoint = '/', queries = {}, fetchOptions = {}) {
  const fetchResponse = await fetchData(endpoint, queries, fetchOptions);
  const jsonResponse = await fetchResponse.json();
  if (jsonResponse) {
    return jsonResponse;
  } else {
    return jsonResponse;
  }
}

export async function blobFetch(endpoint = '/', queries = {}, fetchOptions = {}) {
  const fetchResponse = await fetchData(endpoint, queries, fetchOptions);
  return await fetchResponse.blob();
}

export async function getImgSrc(imageUrl) {
  const imageBlob = await blobFetch(imageUrl);
  const reader = new FileReader();
  reader.readAsDataURL(imageBlob);
  return await new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
  });
}
