import antdId from "antd/lib/locale-provider/id_ID";
import idMessages from "../locales/id_ID.json";

const IdLang = {
  messages: {
    ...idMessages
  },
  antd: antdId,
  locale: 'id'
};
export default IdLang;
