import React from "react";
import { Layout, Popover } from "antd";
// import { Link } from "react-router-dom";
// import { LogoBareskrim } from '../../assets/geospatial/images/provide.images'

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
// import SearchBox from "components/SearchBox";
// import UserInfo from "components/UserInfo";
// import AppNotification from "components/AppNotification";
// import MailNotification from "components/MailNotification";
// import Auxiliary from "util/Auxiliary";
import { useLocation } from 'react-router-dom'
// import { UserProfile } from '../Sidebar/UserProfile'
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import UserProfile from "../Sidebar/UserProfile";

const { Header } = Layout;

const Topbar = () => {

  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  // const {searchText, setSearchText} = useState('');
  const dispatch = useDispatch();

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  // const updateSearchChatUser = (evt) => {
  //   setSearchText(evt.target.value);
  // };
  const location = useLocation()
  let showNameMenu = null
  showNameMenu = (
    location.pathname === '/profile' ? <p>My Account</p> :
      location.pathname === '/summary-hotspot' ? <p>Summary Hotspot</p> :
        location.pathname === '/summary-report' ? <p>Summary Report</p> :
          location.pathname === '/hotspot' ? <p>Hotspot</p> :
            location.pathname === '/messaging' ? <p>Messaging</p> :
              location.pathname === '/broadcast' ? <p>Broadcast</p> :
                location.pathname === '/geospatial-management' ? <p>Geospatial Management</p> :
                  location.pathname === '/task-management' ? <p>Task Management</p> :
                    location.pathname === '/task-management/create' ? <p>Create Task Management</p> :
                      location.pathname === '/socialization' ? <p>Karhutla Socialization</p> :
                        location.pathname === '/build-canal' ? <p>Build Karhutla Canal</p> :
                          location.pathname === '/build-watchtowers' ? <p>Build Karhutla Watchtowers</p> :
                            location.pathname === '/build-reservoir' ? <p>Build Karhutla Reservoir</p> :
                              location.pathname === '/weather-modification' ? <p>Weather Modification</p> :
                                location.pathname === '/hotspot-monitoring' ? <p>Hotspot Monitoring</p> :
                                  location.pathname === '/coordination' ? <p>Coordination</p> :
                                    location.pathname === '/patrol' ? <p>Karhutla Patrol</p> :
                                      location.pathname === '/another-innovation' ? <p>Another Innovation</p> :
                                        location.pathname === '/hotspot-report' ? <p>Hotspot Reporting</p> :
                                          location.pathname === '/law-enforce-report' ? <p>Law Enforcment Reporting</p> :
                                            location.pathname === '/user-management' ? <p>User Management</p> :
                                              location.pathname === '/group-management' ? <p>Group Management</p> :
                                                location.pathname === '/device-management' ? <p>Device Management</p> :
                                                  location.pathname === '/audit-trail' ? <p>Audit Trail</p> :
                                                    location.pathname === '/reporting/10a3151a-7731-4fe7-8a01-46fb25f96f4c' ? <p>Laporan Sosialisasi Karhutla</p> :
                                                      location.pathname === '/reporting/8af8db3f-8459-4f39-a46b-b4647b47705b' ? <p>Laporan Bangun Kanal Karhutla</p> :
                                                        location.pathname === '/reporting/f8d251a4-90e7-4089-9093-375e0913362c' ? <p>Laporan Bangun Menara Karhutla</p> :
                                                          location.pathname === '/reporting/17f26de2-d265-4581-9f66-91dce8554dfa' ? <p>Laporan Bangun Embung Karhutla</p> :
                                                            location.pathname === '/reporting/e4003886-01ad-4a69-8164-ba7d676a3a23' ? <p>Laporan Modifikasi Cuaca</p> :
                                                              location.pathname === '/reporting/3d1938b5-7891-48c4-8486-feb9903dadc4' ? <p>Laporan Koordinasi</p> :
                                                                location.pathname === '/reporting/00c338d7-ab06-4c3d-815f-66d0e6f12e0f' ? <p>Laporan Patroli Karhutla</p> :
                                                                  location.pathname === '/reporting/99230c00-a795-4d67-ac8e-071d39ac3c9f' ? <p>Laporan Inovasi Lain</p> :
                                                                    location.pathname === '/reporting/7809e3b7-9cd5-4bb1-9a08-1b4d1e4cec60' ? <p>Laporan Monitoring Titik Api</p> :
                                                                      location.pathname === '/reporting/e9e51130-6526-4371-9dd3-0bbe5d8afb3e' ? <p>Laporan Pemadaman</p> :
                                                                        location.pathname === '/reporting/f62df73c-5509-4ab8-a774-350fe94607bf' ? <p>Laporan Penegakan Hukum</p> :
                                                                          location.pathname === '/reporting/e6a7be21-7a97-4d7e-8671-b8f6b3595814' ? <p>Laporan Titik Api</p> : location.pathname === '/prediction-hotspot' ? <p>Prediction Hotspot</p> : location.pathname === '/detail-hotspot' ? <p>Detail Hotspot</p> : ""
  )

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3" style={{ color: '#FFF', marginTop: '-7px' }}>
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      {/* <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={LogoBareskrim} width="30px" /></Link> */}
      {width >= TAB_SIZE ?
        <div style={{ fontSize: '20pt', marginTop: '22px', color: '#fff' }}>
          {showNameMenu}
        </div>
        :
        null
      }

      <ul className="gx-header-notifications gx-ml-auto" style={{ marginTop: '-5px' }}>
        <li className="gx-language" style={{ marginRight: '2px' }}>
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
            trigger="click">
            <span className="gx-pointer gx-flex-row gx-align-items-center" style={{ color: '#FFF', fontSize: '12pt' }}>
              <i className={`flag flag-24 flag-${locale.icon}`} />
              <span className="gx-pl-2 gx-language-name">{locale.name}</span>
              <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
            </span>
          </Popover>
        </li>
        <li className="gx-user-nav">
          <UserProfile />
        </li>
        {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo /></li>
          </Auxiliary>
        } */}
      </ul>
      {/* <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
                placeholder="Search in app..."
                onChange={updateSearchChatUser}
                 value={searchText}/> */}
      {/* <ul className="gx-header-notifications gx-ml-auto">
        <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
            <SearchBox styleName="gx-popover-search-bar"
                      placeholder="Search in app..."
                      onChange={updateSearchChatUser}
                      value={searchText}/>
          } trigger="click">
            <span className="gx-pointer gx-d-block"><i className="icon icon-search-new"/></span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                      trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                      content={<MailNotification/>} trigger="click">
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new"/>
                    <span className="gx-status gx-status-rtl gx-small gx-orange"/>
                  </span>
              </Popover>
            </li>
          </Auxiliary>
        }
        <li className="gx-language">
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
                  trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
          </Popover>
        </li>
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        }
      </ul> */}
    </Header>
  );
};

export default Topbar;
