// User profile
export const USER_TOKEN = 'USER_TOKEN';
export const USER_ID = 'USER_ID';
export const USER_NAME = 'USER_NAME';
export const USER_DESCRIPTION = 'USER_DESCRIPTION';
export const USER_EMAIL = 'USER_EMAIL';
export const USER_PHONE = 'USER_PHONE';
export const BRANCH_OFFICE_NAME = 'BRANCH_OFFICE_NAME';
export const BRANCH_OFFICE_ID = 'BRANCH_OFFICE_ID';

// User Group
export const GROUP_ID = 'GROUP_ID'
export const GROUP_NAME = 'GROUP_NAME'

// Constants
export const FEATURES = 'FEATURES'
export const STATUSES = 'STATUSES'
export const ACCESS = 'ACCESS'

// User Menu Saved Configuration
export const GEOSPATIAL_CONFIG = 'GEOSPATIAL_CONFIG'
export const GEOSPATIAL_CONFIG_HOTSPOT = 'GEOSPATIAL_CONFIG_HOTSPOT'