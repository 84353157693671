import { apiFetch } from '../../util/MainApiFetch'

export async function readData(queries) {
    return await apiFetch('/user/user/current', queries, {})
}

export async function readCurrentUser() {
    return await readData({})
}

