import React, { Component } from "react"
import { Avatar, Popover, Popconfirm } from "antd"
import { clear } from "../../util/Storage"
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages"

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout = async (e) => {
    e.preventDefault()
    clear()
    window.location.href = '/'
  }
  render() {
    const text = <IntlMessages id="profile.logout.confirmation" />
    const yes = <IntlMessages id="profile.logout.yes" />
    const no = <IntlMessages id="profile.logout.no" />
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li><Link to="/profile" style={{color: "#545454"}}><IntlMessages id="profile.myaccount" /></Link></li>
        <Popconfirm placement="right" title={text} onConfirm={(e) => this.handleLogout(e)} okText={yes} cancelText={no}>
          <li><IntlMessages id="profile.logout" /></li>
        </Popconfirm>
      </ul>
    )
    return (
<Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
      trigger="click">
      <Avatar src={require("assets/images/avatar/domnic-harris.png")}
        className="gx-avatar gx-pointer" alt="" />
    </Popover>

    )
  }
}

export default (UserProfile)
