import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from '../util/asyncComponent'

const App = ({ match, socketio }) => (
  <>
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}summary-report`} component={asyncComponent(() => import('../pages/dashboard/SummaryReport.page'))} key="1"/>
      <Route path={`${match.url}summary-hotspot`} component={asyncComponent(() => import('../pages/dashboard/SummaryHotspot.page'))} key="2"/>
      <Route path={`${match.url}prediction-hotspot`} component={asyncComponent(() => import('../pages/dashboard/PredictionHotspot.page'))} key="3"/>
      <Route path={`${match.url}profile`} component={asyncComponent(() => import('../pages/Profile.page'))} key="4"/>
      {/* <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('../pages/Dashboard.page'))} /> */}
      <Route path={`${match.url}hotspot`} component={asyncComponent(() => import('../pages/hotspot/Hotspot.page'))} />
      <Route path={`${match.url}detail-hotspot`} component={asyncComponent(() => import('../pages/hotspot/DetailHotspot.page'))} />
      <Route path={`${match.url}messaging`} component={asyncComponent(() => import('../pages/Messaging.page'), socketio) } />
      <Route path={`${match.url}broadcast`} component={asyncComponent(() => import('../pages/Broadcast.page'))} />
      <Route path={`${match.url}user-management`} component={asyncComponent(() => import('../pages/UserManagement.page'))} />
      {/* <Route path={`${match.url}group-management`} component={asyncComponent(() => import('../pages/GroupManagement.page'))} /> */}
      <Route path={`${match.url}device-management`} component={asyncComponent(() => import('../pages/DeviceManagement.page'))} />
      <Route path={`${match.url}geospatial-management`} component={asyncComponent(() => import('../pages/GeospatialManagement.page'))} />
      <Route path={`${match.url}device-management`} component={asyncComponent(() => import('../pages/DeviceManagement.page'))} />
      <Route path={`${match.url}audit-trail`} component={asyncComponent(() => import('../pages/AuditTrail.page'))} />
      <Route path={`${match.url}task-management/:taskId/edit`} component={asyncComponent(() => import('../pages/TaskEdit.page'))} />
      <Route path={`${match.url}task-management/create`} component={asyncComponent(() => import('../pages/TaskCreate.page'))} />
      <Route path={`${match.url}task-management/:taskId`} component={asyncComponent(() => import('../pages/TaskShow.page'))} />
      <Route path={`${match.url}task-management`} component={asyncComponent(() => import('../pages/Task.page'))} />
      <Route path={`${match.url}reporting/:typeSlug/:typeId`} component={asyncComponent(() => import('../pages/Reporting.page'))} />
      <Route path={`${match.url}reporting/:typeSlug`} component={asyncComponent(() => import('../pages/Reporting.page'))} />
    </Switch>
  </div>
  </>
)

export default App
