// Main API
export const HOST = window.origin
export const API_ROOT = `${HOST}/api/v1`
export const API_KEY = '$2y$12$5QVMuL1Jl43tmCnrwFkLHONCVL/nfG5ux.YaPSA8imdnjtZVDnsWi'

// Chat & Hotspot API
export const HOST_TWO = window.origin
export const API_ROOT_TWO = `${HOST_TWO}/api/v1`
export const API_KEY_TWO = '$2y$12$5QVMuL1Jl43tmCnrwFkLHONCVL/nfG5ux.YaPSA8imdnjtZVDnsWi'

// Images API
export const HOST_THREE = window.origin
export const API_ROOT_THREE = `${HOST_THREE}/files`
export const API_KEY_THREE = '$2y$12$5QVMuL1Jl43tmCnrwFkLHONCVL/nfG5ux.YaPSA8imdnjtZVDnsWi'

// Socket IO
export const HOST_SOCKET_IO = window.origin
export const HOST_ROOT_SOCKET_IO = `${HOST_SOCKET_IO}`

// Predictif AI
export const HOST_PREDICT = window.origin
export const HOST_ROOT_PREDICT = `${HOST_PREDICT}`

