import React, { PureComponent } from "react"
import { Avatar, Popover, Popconfirm } from "antd"
import { clear } from "../../util/Storage"
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages"
import { readCurrentUser } from "../../apis/userManagement/user_current_read"
import { API_ROOT_THREE } from "../../configs/configs"
import { map } from "lodash"
import { readPermission } from "../../apis/groupManagement/group_permission_by_feature_n_access"

class UserProfile extends PureComponent {
  constructor(props) {
    super(props)

    this._isMounted = false

    this.state = {
      dataCurrentUser: [],
      mergeData: [],
    }
    this.handleLogout = this.handleLogout.bind(this)
  }

  onHandlePermission = async () => {
    let access_type = JSON.parse(localStorage.getItem('ACCESS'))
    let feature = JSON.parse(localStorage.getItem('FEATURES'))
    let permission = JSON.parse(localStorage.getItem('PERMISSION'))
    let { mergeData } = this.state

    if (permission === null) {
      map(feature, f => (
        map(access_type, access => {
          let features = f
          let data = { ...features, access_id: access.id, access_name: access.name }
          mergeData.push(data)
          this._isMounted && this.setState({
            mergeData
          })
          localStorage.setItem('PERMISSION', JSON.stringify(mergeData))
        })
      ))
      await this.onHandleReadPermission()
    } else {
      await this.onHandleReadPermission()
    }
  }

  onHandleReadPermission = async () => {
    let getPermissionLocal = JSON.parse(localStorage.getItem('PERMISSION'))

    // Read User Management
    let permission_user_management = getPermissionLocal.filter(o => o.name === 'USER_MANAGEMENT' && o.access_name === 'READ')
    const getPermission_UserManagement = await readPermission(permission_user_management[0].id, permission_user_management[0].access_id)
    if (getPermission_UserManagement.data) {
      const pageUserManagement = <li><Link to="/user-management" style={{ color: "#545454" }}><IntlMessages id="sidebar.user.management" /></Link></li>
      this._isMounted && this.setState({
        UserManagement_Page: pageUserManagement
      })
    }

    // Read Device Management
    let permission_device_management = getPermissionLocal.filter(o => o.name === 'DEVICE_MANAGEMENT' && o.access_name === 'READ')
    const getPermission_DeviceManagement = await readPermission(permission_device_management[0].id, permission_device_management[0].access_id)
    if (getPermission_DeviceManagement.data) {
      const pageDeviceManagement = <li><Link to="/device-management" style={{ color: "#545454" }}><IntlMessages id="sidebar.device.management" /></Link></li>
      this._isMounted && this.setState({
        DeviceManagement_Page: pageDeviceManagement
      })
    }

    // Read Audit Trail
    let permission_audit_trail = getPermissionLocal.filter(o => o.name === 'AUDIT_LOG' && o.access_name === 'READ')
    const getPermission_AuditTrail = await readPermission(permission_audit_trail[0].id, permission_audit_trail[0].access_id)
    if (getPermission_AuditTrail.data) {
      const pageAuditTrail = <li><Link to="/audit-trail" style={{ color: "#545454" }}><IntlMessages id="profile.audit.trail" /></Link></li>
      this._isMounted && this.setState({
        AuditTrail_Page: pageAuditTrail
      })
    }
  }

  onHandleRead = async () => {
    try {
      let data = await readCurrentUser()
      let PhotoProfile = data.iconFilename
      let GET_photoProfile = API_ROOT_THREE + "/uploads/user/user/icon/" + PhotoProfile
      let Photo = GET_photoProfile.toString();
      let bypassGroupId = data.groupId
      let Name = data.name
      let Description = data.description
      let Email = data.email
      let Phone = data.phone
      let Properties = data.properties
      this._isMounted && this.setState({
        dataCurrentUser: data,
        groupId: bypassGroupId,
        userPhoto: Photo,
        userFullName: Name,
        userDescription: Description,
        userEmail: Email,
        userPhone: Phone,
        Properties: Properties,
        PhotoProfile_GET: PhotoProfile === '' ? null : PhotoProfile,
        AvatarName: Name
      })
    } catch (err) {
      console.log(err)
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this._isMounted && await this.onHandleRead()
    this._isMounted && await this.onHandlePermission()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleLogout = async (e) => {
    e.preventDefault()
    clear()
    window.location.href = '/'
  }

  render() {
    const { userPhoto, userFullName, UserManagement_Page, DeviceManagement_Page, AuditTrail_Page, PhotoProfile_GET } = this.state

    const text = <IntlMessages id="profile.logout.confirmation" />
    const yes = <IntlMessages id="profile.logout.yes" />
    const no = <IntlMessages id="profile.logout.no" />
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li><Link to="/profile" style={{ color: "#545454" }}><IntlMessages id="profile.myaccount" /></Link></li>
        {UserManagement_Page}
        {/* <li><Link to="/group-management" style={{ color: "#545454" }}><IntlMessages id="sidebar.group.management" /></Link></li> */}
        {DeviceManagement_Page}
        {AuditTrail_Page}
        <Popconfirm placement="right" title={text} onConfirm={(e) => this.handleLogout(e)} okText={yes} cancelText={no}>
          <li><IntlMessages id="profile.logout" /></li>
        </Popconfirm>
      </ul>
    )
    
    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-3 gx-avatar-row" style={{ marginTop: '15px' }}>
      {
        PhotoProfile_GET === null 
        ? <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
            <Avatar className="gx-size-35 gx-pointer gx-mr-3" alt="" />
            <span className="gx-avatar-name" style={{ color: '#FFF', fontSize: '12pt' }}>{userFullName}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
          </Popover>
        : <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
            <Avatar src={userPhoto}
              className="gx-size-35 gx-pointer gx-mr-3" alt="" />
            <span className="gx-avatar-name" style={{ color: '#FFF', fontSize: '12pt' }}>{userFullName}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
          </Popover>
      }
      </div>
    )
  }
}

export default (UserProfile)
