import React from "react"
import ReactDOM from "react-dom"
import "./assets/geospatial/scss/custom.scss"
import NextApp from './NextApp'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import registerServiceWorker from './registerServiceWorker'
import {AppContainer, setConfig} from 'react-hot-loader'

setConfig({
  showReactDomPatchNotification: false
})

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component/>
    </AppContainer>,
    document.getElementById('root')
  );
};

registerServiceWorker()
unregisterServiceWorker()

render(NextApp)

if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp)
  })
}
